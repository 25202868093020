import React from "react";
import bg from "./images/bg1.jpeg";

function Thankyou() {
  return (
    <div
      className={`w-full h-screen overflow-auto bg-center	bg-no-repeat bg-cover p-8 text-center font-bold flex justify-center items-center flex-col`}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <p className="text-4xl text-center">ขอขอบคุณจากใจ</p>
      <p className="text-xl text-center mt-12">จากครอบครัว อารยะสัจพงษ์</p>
    </div>
  );
}

export default Thankyou;
