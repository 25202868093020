import { useState } from "react";
import axios from "axios";
import Sadness from "./Sadness";
import Thankyou from "./Thankyou";

function App() {
  const [isComplete, setisComplete] = useState(false);
  const onSubmit = async (value) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/narong`, value);
      setisComplete(true);
    } catch (error) {
      console.log(error);
    }
  };
  if (isComplete) {
    return <Thankyou />;
  }
  return <Sadness onSubmit={onSubmit} />;
}

export default App;
