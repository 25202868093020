import { useState } from "react";
import bg from "./images/bg1.jpeg";
import corner from "./images/corner.png";
import papa from "./images/papa.jpg";

function App({ onSubmit }) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = () => {
    onSubmit({ name, message });
  };
  return (
    <div
      className={`w-full h-screen overflow-auto bg-center	bg-no-repeat bg-cover p-8 text-center font-bold`}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <p className="text-4xl font-bold text-center">ร่วมไว้อาลัย</p>
      <div className="flex justify-center items-center m-auto mt-[20px] relative">
        <div className="p-[14px] bg-gradient-to-b from-[rgba(140,140,140,0.5)] to-[rgba(100,100,100,0.7)] relative overflow-hidden">
          <img src={papa} alt="placeholder" className="" width={300} />
          <div className="absolute w-[300px] h-[24px] top-[75%] bg-gradient-to-r from-white to-[#c8c8c8] left-[-150px] rotate-[45deg]" />
        </div>
      </div>
      <p className="text-4xl mt-8">นายณรงค์ อารยะสัจพงษ์</p>
      <p className="text-2xl mt-4">ชาตะ ๒๗ มิถุนายน ๒๔๙๘</p>
      <p className="text-2xl">มรณะ ๑๕ เมษายน ๒๕๖๕</p>
      <div className="bg-[white] w-full max-w-2xl m-auto rounded-xl px-8 relative py-[100px] desktop:py-[40px] mt-10">
        <div className="absolute top-[10px] right-[10px]">
          <img src={corner} alt="line" width={100} className="m-auto" />
        </div>
        <div className="absolute bottom-[10px] left-[10px] rotate-180">
          <img src={corner} alt="line" width={100} className="m-auto" />
        </div>
        <div className="absolute bottom-[10px] right-[10px] rotate-90">
          <img src={corner} alt="line" width={100} className="m-auto" />
        </div>
        <div className="absolute top-[10px] left-[10px] rotate-[270deg]">
          <img src={corner} alt="line" width={100} className="m-auto" />
        </div>
        <div className="desktop:flex items-center w-full max-w-md text-left m-auto">
          <p className="w-[140px]">นามผู้ร่วมไว้อาลัย</p>
          <input
            className="w-full outline-0 p-1 border-b border-black"
            defaultValue=""
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="desktop:flex items-start w-full max-w-md text-left mt-[20px] m-auto">
          <p className="w-[140px]">ข้อความ</p>
          <textarea
            className="w-full outline-0 p-1 border-b border-black"
            defaultValue=""
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
      <button
        className="rounded-xl w-[200px] text-xl flex justify-center items-center m-auto font-bold bg-white from-white to-[#c8c8c8] h-[45px] mt-[20px] mb-[320px] desktop:mb-[30px]"
        onClick={handleSubmit}
      >
        ส่งข้อความ
      </button>
    </div>
  );
}

export default App;
